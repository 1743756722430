import gql from "graphql-tag"
/* ========== Recent News ======== */
const RecentNews = gql`
query GetNews{
    newsBlogs (sort:"News_Published_Date:desc", limit:6) {
        id
        URL
        Title
        News_Published_Date
        Tile_Video_URL
        Tile_Image {
          url
          alternativeText
        }
        Select_News_Category {
          Name
          URL
        }
        imagetransforms
    }
}`

/* ========== All News ======== */
const AllNews = gql`
query AllNews($limit: Int!, $start: Int!){
    newsBlogs (sort:"News_Published_Date:desc", start:$start, limit:$limit) {
        id
        URL
        Title
        News_Published_Date
        Above_Content
        Tile_Video_URL
        Tile_Image {
          url
          alternativeText
        }
        Select_News_Category {
          Name
          URL
        }
        imagetransforms
    }
}`

/* ========== Category News ======== */
const CategoryNews = gql`
query GetCategoryNews($category: String!){
    newsBlogs(where:{Select_News_Category:{URL_contains:$category}}, sort:"News_Published_Date:desc") {
      id
      URL
      Title
      News_Published_Date
      Tile_Video_URL
      Tile_Image {
        url
        alternativeText
      }
      Select_News_Category {
        Name
        URL
      }
      imagetransforms
    }
}`

/* ========== No Results Properties ======== */
const NoResultProperties = gql`
query NoResultProperties($status: String!, $department: String!){
    properties(limit:6, where:{status:$status, department:$department, publish:true}) {
        id
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
        crm_negotiator_id
        bedroom
        reception
        bathroom
        description
    }

}`

/* ========== Team Listing ======== */
const AllTeams = gql`
query AllTeams{
    teams(sort: "Rank:ASC") {
        id
        Name
        URL
        Designation
        Phone
        Email
        Designation
        imagetransforms
        Video_URL
        Staff_Image {
          url
          alternativeText
        }
    }
}`

/* ========== Other Staffs ======== */
const OtherStaffs = gql`
query OtherStaffs ($url: String!) {
    teams (limit:4,where:{URL_ne: $url}){
        id
        Name
        URL
        Designation
        Phone
        Email
        Designation
        imagetransforms
        Video_URL
        Staff_Image {
          url
          alternativeText
        }
    }
}`

/* ========== Area Guides ======== */
const AllAreaguides = gql`
query AllAreaguides {
    areaGuides {
      Name
      Tile_Intro
      Video_URL
      id
      imagetransforms
      Latitude
      Longitude
      Tile_Image {
        url
        alternativeText
      }
      URL
    }
}`

/* ========== Member Contact Detail ======== */
const MemberContactDetail = gql`
query MemberContactDetail($id: String!) {
  teams (where:{id:$id}) {
    id
    Name
    Email
    Phone   
  }
}`

/* ========== Property Detail For Booking ======== */

const GetPropertyBooking = gql`
query GetPropertyOffice($id: String!) {
  properties (where:{id:$id}) {
    id
    slug
    display_address
    search_type
    department
    title
    images
    bedroom
    bathroom
    office_crm_id
    price
  }
}`
/* ========== Property Area Guide ======== */
const AreaGuideDetail = gql`
query AreaGuideDetail($postcode: String!) {
    areaGuides (where:{Postcode_Mapping_contains:$postcode}) {
      id
      Name
      URL
      Above_Content
      Postcode_Mapping
      Tile_Image {
        url
        alternativeText
      }
      imagetransforms
  }
}`


export const Queries = {
    RecentNews,
    CategoryNews,
    NoResultProperties,
    AllTeams,
    OtherStaffs,
    AllNews,
    AllAreaguides,
    MemberContactDetail,
    GetPropertyBooking,
    AreaGuideDetail
};


