import { useQuery} from '@apollo/client';
import { Queries } from "./common_queries";

export const RecentNews = () => {
    return useQuery(Queries.RecentNews);
}
export const AllNews = (start, limit) => {
    return useQuery(Queries.AllNews,{
        variables: {start: start, limit: limit}
    });
}

export const CategoryNews = (category) => {
    return useQuery(Queries.CategoryNews,{
        variables: {category: category}
    });
}

export const NoResultProperties = (status, department) => {
    return useQuery(Queries.NoResultProperties, {
        variables: {status: status, department: department}
    });
}

export const AllTeams = () => {
    return useQuery(Queries.AllTeams);
}

export const AllAreaguides = () => {
    return useQuery(Queries.AllAreaguides);
}

export const OtherStaffs = (url) => {
    return useQuery(Queries.OtherStaffs,{
        variables: {url: url}
    });
}

export const MemberContactDetail = (id) => {
    return useQuery(Queries.MemberContactDetail,{
        variables: {id: id}
    });
}

export const GetPropertyBooking = (id) => {
    return useQuery(Queries.GetPropertyBooking,{
        variables: {id: id}
    });
}

export const AreaGuideDetail = (postcode) => {
    return useQuery(Queries.AreaGuideDetail,{
        variables: {postcode: postcode}
    });
}

